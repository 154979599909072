body {
  background-color: #272822;
  height: 100vh;
  line-height: 1.5;
  margin: 0;
  position: relative;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}
