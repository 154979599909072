.footer {
  align-items: center;
  display: flex;
  bottom: 0;
  height: 0;
  background-color: #414339;
  position: absolute;
  width: 100%;
  transition: height 0.75s linear;
}

.show {
  height: 4em;
}

.footer-text {
  color: #ccccc7;
  margin: 0 auto;
  opacity: 0;
  transition: opacity 0.5s linear 1s;
}

.visible {
  opacity: 1;
}
