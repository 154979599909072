#where {
  font-size: 1.5em;
}

.contact {
  background-color: #fd971f;
  border-radius: 0;
  display: flex;
  height: 0;
  margin: 0 auto;
  padding: 0;
  width: 3px;
  transition: height 2s linear, margin 2s linear, padding-top 2s linear,
    padding-bottom 2s linear, width 2s linear 2s, padding-left 2s linear 2s,
    padding-right 2s linear 2s, background-color 1s linear 4s,
    border-radius 1s linear 4s;
}

.contact.show {
  background-color: #cfcfc2;
  border-radius: 10px;
  height: 50px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em;
  width: 300px;
}

.svg {
  height: 0;
  width: 0;
}

.svg#github {
  transition: height 0.25s linear 4s, width 0.25s linear 5s;
}

.svg#gmail {
  transition: height 0.25s linear 4.25s, width 0.25s linear 5.25s;
}

.svg#linkedin {
  transition: height 0.25s linear 4.5s, width 0.25s linear 5.5s;
}

.svg.grow {
  height: 100%;
  width: 100%;
}

a {
  margin: 0 6.67%;
  width: 20%;
}
